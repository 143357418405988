body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Open Sans', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fff;
  color: #0f1c32;
  letter-spacing: -0.02em;
  line-height: 1.5;
}

.MuiTab-root {
  text-transform: capitalize !important;
  opacity: 1 !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
}

a:active {
  color:#375fd7;
}

.MuiButtonBase-root {
  letter-spacing: .12rem !important;
}

.gradientGlow {
  -webkit-text-fill-color: #0000;
  background: #375fd7;

  -webkit-background-clip: text;
}

.wrapper {
  width: 150px;
  height: 150px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.container {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(255, 255, 255, 0.2);
  border-radius: 30px;
}

.item {
  width: inherit;
  height: inherit;
  background: white;
  transform-origin: 50% 100%;
}

#map {
  height: 60vh;
}
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
}

.settingsHover:hover {
  background-color: rgba(5, 14, 44, 0.075);
  border-radius: 10px;
  cursor: pointer;
}